/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap"); */

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overlay {
  position: absolute; /* Sit on top of the page content */
  /*display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.titlesection {
  position: relative;
  font-weight: bold;
  padding: 5px 10px;
  color: #1f1f1f !important;
}
.titlesection:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.1rem;
  width: 4px;
  height: 100%;
  background-color: #1bc5bd;
}

.subtitlesection {
  position: relative;
  padding: 0 15px !important;
  margin: 10px 0 !important;
  color: #000 !important;
}

.check-box-title {
  margin-bottom: 10px;
  cursor: pointer;
}

.check-box-title label {
  margin: 0 !important;
  color: #000;
}

.updateprecintlabel label {
  margin: 0 !important;
}
.updateprecintlabel .MuiFormControl-root {
  flex-direction: row !important;
}

/* Us styles. Luego esto habrá que organizarlo como componentes, todo el código en realidad XD */
/*.makeStyles-drawerClose-9 {
  width: 80px !important;
}
.makeStyles-drawerClose-9 .showelementmenu {
  display: none !important;
}
.makeStyles-drawerClose-9 .hiddenelementmenu {
  visibility: hidden !important;
}
.makeStyles-drawerClose-9 .iconposition {
  display: flex !important;
  justify-content: center !important;
  width: 100%;
}

.makeStyles-drawerOpen-8 .traventialogo {
  display: block !important;
}

.makeStyles-drawerOpen-8 .traventiaicon {
  display: none !important;
}*/

/* Overrides */
.providersclass {
  display: flex;
  justify-content: center;
  align-items: center;
}
