.InputBlock {
  width: 100%;
  display: flex;
}

.InputBlockSide {
  width: 50%;
  padding: 20px;
}

.InputBlockTitle {
  margin-bottom: 10px;
}

.ProgressLoading {
  color: orange;
}

.ProgressSuccess {
  color: green;
}

.ProgressIcon {
  margin-left: 10px;
  margin-right: 5px;
}

.ReSendLink {
  cursor: pointer;
  color: rgb(5, 99, 193);
}
