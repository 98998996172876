body {
  font-size: 13px !important;
  background-color: #eef0f8 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.customscrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.customscrollbar::-webkit-scrollbar:vertical {
  width: 8px;
}

.customscrollbar::-webkit-scrollbar-button:increment,
.customscrollbar::-webkit-scrollbar-button {
  display: none;
}

.customscrollbar::-webkit-scrollbar:horizontal {
  height: 10px;
}

.customscrollbar::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 1px solid #f1f2f3;
}

.customscrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}
