.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

#modalPreview {
  position: absolute !important;
  top: 38% !important;
  left: 50% !important;
  transform: translate(50%, -50%) !important;
}

/* .modal-fade1 {
  z-index: 10 !important;
  background-color: blue !important;
  position: absolute !important;
  top: 500% !important;
  left: 50% !important;
  transform: translate(-1000%, -50%) !important;
} */

.modal-fade2 {
  z-index: 200000 !important;
}
.divCenter {
  text-align: center !important;
}
.divCenterWithMarginTop {
  text-align: center !important;
  margin-top: 12px !important;
  color: #1bc5bd;
  font-weight: 550;
}
.loadingImgText {
  font-size: 1.2em !important;
  margin-bottom: 10px;
}
.onChangeMultipleFilesText {
  color: #ffae17;
  font-weight: 550;
  margin-top: 10px;
}
.cropper-modal {
  background-color: #fff !important;
  opacity: 0.5;
}
