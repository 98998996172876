body {
  background-color: #f4f4f7;
}
/*Custo Bootstrap*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  border-radius: 0.125rem;
}

.card {
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  margin-bottom: 1rem;
  border-radius: 0.375rem;
}
.card-body {
  padding: 1.4rem !important;
}
.bg-enable {
  background-color: #2ed4a2;
}
.bg-enable-muted {
  background-color: #baffea8e;
}
.bg-disable {
  background-color: #ccc;
}
.badge {
  border-radius: 20px !important;
}
.market-no-select-class {
  background-color: #fff !important;
}
.market-select-class {
  background-color: #d8eef8 !important;
}

/*Custo Material Ui*/

.MuiFormControl-root {
  width: 100% !important;
}
.MuiDialog-paper {
  border-bottom: 7px solid #ffae17;
}
@media (max-width: 992px) {
  .MuiTableCell-head {
    vertical-align: bottom !important;
  }
}
